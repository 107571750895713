<template>
  <div class="main-header">
    <div class="logo">
      <img src="@/assets/images/logo2.png" alt/>
    </div>

    <div @click="sideBarToggle" class="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">

      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
            id="dropdown-1"
            text="Dropdown Button"
            class="m-md-2 user col align-self-end"
            toggle-class="text-decoration-none"
            no-caret
            variant="link"
        >
          <template slot="button-content">
            <img
                :src="image_thumb"
                id="userDropdown"
                alt
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            />
          </template>

          <div
              class="dropdown-menu-right"
              aria-labelledby="userDropdown"
          >
            <template v-if="user">
              <div class="dropdown-header">
                <i class="i-Lock-User mr-1"></i> {{ username }}
              </div>

              <div class="dropdown-header">
                <i class="i-Email mr-1"></i> {{ user.email }}
              </div>
            </template>
            <router-link class="dropdown-header link" :to="{name: 'ClientSettings'}">
              <i class="i-Security-Check mr-1"></i>Settings
            </router-link>
            <div class="dropdown-header link" @click.prevent="logoutUser">
              <i class="i-Remove-User mr-1"></i>Sign out
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>

  </div>

</template>

<script>
import {isMobile} from 'mobile-device-detect';
import {mapGetters, mapActions} from 'vuex';
import {mixin as clickaway} from 'vue-clickaway';


export default {
  mixins: [clickaway],
  components: {},
  data() {
    return {
      searchQuery: null,
      calendarDate: new Date(),
      isDisplay: true,
      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false
    };
  },
  computed: {
    ...mapGetters(['getSideBarToggleProperties']),
    user() {
      return this.$store.getters.user
    },
    username() {
      if (this.$store.getters.user) {
        return `${this.$store.getters.user.name}`;
      }
      return 'ANONYMOUS USER';
    },
    image_thumb() {
      let thumb = require('@/assets/images/man-profile.svg');
      if (
          this.$store.getters.user &&
          this.$store.getters.user.image_thumb
      ) {
        return this.$store.getters.user.image_thumb;
      } else if (
          this.$store.getters.user &&
          this.$store.getters.user.image
      ) {
        return this.$store.getters.user.image;
      }
      return thumb;
    }
  },
  methods: {
    ...mapActions([
      'changeSecondarySidebarProperties',
      'changeSidebarProperties',
      'changeThemeMode',
      'signOut'
    ]),
    logoutUser() {
      localStorage.clear();
      this.$store.dispatch('logoutClient')
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle() {
      if (
          this.getSideBarToggleProperties.isSideNavOpen &&
          this.getSideBarToggleProperties.isSecondarySideNavOpen &&
          isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
          this.getSideBarToggleProperties.isSideNavOpen &&
          this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
          !this.getSideBarToggleProperties.isSideNavOpen &&
          !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
          !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
          !this.getSideBarToggleProperties.isSideNavOpen &&
          !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      }
    }
  }
};
</script>

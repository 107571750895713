<template>
  <div
      class="side-content-wrap"
      @mouseenter="isMenuOver = true"
      @mouseleave="isMenuOver = false"
      @touchstart="isMenuOver = true"
  >

    <vue-perfect-scrollbar
        ref="myData"
        :class="{ open: getSideBarToggleProperties.isSideNavOpen, 'remove-top': !showTop }"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        class="sidebar-left rtl-ps-none ps scroll"
    >

      <div>
        <ul class="navigation-left">
          <li
              :class="{ active: selectedParentMenu === 'dashboard' }"
              class="nav-item"
              data-item="dashboard"
              @mouseenter="toggleSubMenu"
          >
            <router-link
                class="nav-item-hold"
                href="#"

                :to="{name: 'ClientDashboard'}"
            >
              <i class="nav-icon i-Home1"></i>
              <span class="nav-text">Home</span>
            </router-link>
            <div class="triangle"></div>
          </li>

          <li
              v-if="!showTop"
              :class="{ active: selectedParentMenu === 'back' }"
              data-item="back"
              class="nav-item"
              @mouseenter="toggleSubMenu"
          >
            <a

                @click="backTo"
                class="nav-item-hold"
                href="#"
            >
              <i class="nav-icon i-Back1"></i>
              <span class="nav-text">Back</span>
            </a>
          </li>




        </ul>
      </div>
    </vue-perfect-scrollbar>

    <vue-perfect-scrollbar
        :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        class="sidebar-left-secondary ps rtl-ps-none"
    >
      <div ref="sidebarChild">
        <!-- Submenu Dashboards -->
        <ul
            :class="{ 'd-block': selectedParentMenu === 'dashboard' }"
            class="childNav d-none"
            data-parent="dashboard"
        >
          <li class="nav-item">
            <router-link :to="{name: 'ClientDashboard'}">
              <i class="nav-icon i-Statistic"></i>
              <span class="item-name">Dashboard</span>
            </router-link>
          </li>

        </ul>



      </div>
    </vue-perfect-scrollbar>
    <div
        :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
        class="sidebar-overlay"
        @mouseenter="removeOverlay()"
    ></div>
  </div>
</template>

<script>
import {isMobile} from 'mobile-device-detect';

import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    showTop: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: '',
      isMobile,
      configList: []
    };
  },
  mounted() {
    this.toggleSelectedParentMenu();
    window.addEventListener('resize', this.handleWindowResize);
    document.addEventListener('click', this.returnSelectedParentMenu);
    this.handleWindowResize();
    // this.getConfigList();
  },

  beforeDestroy() {
    document.removeEventListener('click', this.returnSelectedParentMenu);
    window.removeEventListener('resize', this.handleWindowResize);
  },
  watch: {
    $route() {
      this.returnSelectedParentMenu();
    }
  },
  computed: {
    ...mapGetters(['getSideBarToggleProperties'])
  },
  methods: {
    ...mapActions([
      'changeSecondarySidebarProperties',
      'changeSecondarySidebarPropertiesViaMenuItem',
      'changeSecondarySidebarPropertiesViaOverlay',
      'changeSidebarProperties'
    ]),
    getLinks(section, filterDefault) {
      if (this.configList) {
        return this.configList
            .filter(
                (c) =>
                    c.section === section && c.isdefault === filterDefault
            )
            .map((x) => ({
              value: x.id,
              text: x.name
            }));
      }
      return [];
    },
    handleWindowResize() {
      //  console.log('not working is Mobile');
      if (window.innerWidth <= 1200) {
        if (this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
        if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
          this.changeSecondarySidebarProperties();
        }
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path
          .split('/')
          .filter((x) => x !== '')[0];

      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = 'dashboards';
      }
    },
    toggleSubMenu(e) {
      // let childrens = this.$refs.sidebarChild.children;
      let parent = e.target.dataset.item;

      this.selectedParentMenu = parent;
      setTimeout(() => {
            this.changeSecondarySidebarPropertiesViaMenuItem(true);
          },
          1000);
    },
    highlightOnly(e) {
      let parent = e.target.dataset.item;
      this.selectedParentMenu = parent;
      this.changeSecondarySidebarPropertiesViaMenuItem(false);
    },
    removeOverlay() {
      this.changeSecondarySidebarPropertiesViaOverlay();
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
      }
      this.toggleSelectedParentMenu();
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        this.toggleSelectedParentMenu();
      }
    },
    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll(
          '.dropdown-sidemenu.open'
      );

      event.currentTarget.classList.toggle('open');

      dropdownMenus.forEach((dropdown) => {
        dropdown.classList.remove('open');
      });
    },
    backTo() {
      this.$router.go(-1)
    }
  }
};
</script>

<style scoped>
.remove-top {
  top: 0 !important;
  height: 100%;
}
</style>

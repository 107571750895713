<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <top-nav v-if="showTop"/>

    <sidebar :showTop="showTop"/>

    <main>
      <div
          :class="{ 'sidenav-open': getSideBarToggleProperties.isSideNavOpen, 'm-0': !showTop  }"
          class="main-content-wrap d-flex flex-column"
      >
        <transition name="page" mode="out-in">
          <router-view/>
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import Sidebar from "./Sidebar";
import TopNav from "./TopNav";

export default {
  components: {
    Sidebar,
    TopNav
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.showTopNav()
      }
    }
  },
  data() {
    return {
      showTop: true
    };
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
  },
  methods: {
    showTopNav() {
      this.showTop = !this.$route.path.includes('questionnaires')
    }
  },
};
</script>

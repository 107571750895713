<template>
  <div>
    <component :is="'Page'"  @changePassword="$emit('changePassword')"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Page from './layout/index'

export default {
  components: {
    Page
  },
  created() {
    console.log('client page created inspect token')
    this.$store.dispatch('inspectToken', true);
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getThemeMode'])
  },
  methods: {}
};
</script>
